module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"mecm1993-personal-site","short_name":"starter","start_url":"/","background_color":"#161616","theme_color":"#161616","display":"minimal-ui","icon":"src/images/favicon-64x64.png","icons":[{"src":"/icons/favicon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/icons/favicon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/icons/favicon-48x48.png","sizes":"48x48","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"03c17839b3b37d8b923c1ec73b9eb91a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
